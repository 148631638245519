import * as yup from 'yup';
import { MARKETPLACE_TABS } from './constants';
import { assocPath } from 'ramda';

yup.setLocale({
  mixed: {
    required: 'fieldRequired',
  },
  string: {
    email: 'invalidEmail',
    url: 'invalidUrl',
    max: 'maxStringLength',
  },
  array: {
    min: 'minItems',
    max: 'maxItems',
  },
});

const appTabValidations = yup.object().shape({
  // category_ids returns a custom error message if the max length is exceeded
  category_ids: yup
    .array()
    .of(yup.string())
    .required()
    .test('number-of-categories', 'maxCategories', (value) => {
      return value.length <= 3;
    }),
  description: yup.string().required().max(2000),
  tagline: yup.string().required().max(100),
  built_by: yup.string().required(),
  app_version_id: yup.string().required(),
  app_version: yup.object().required(),
  draft_developer_notes: yup.string().required(),
  about: yup.string().required(),
  how_it_works: yup.string().required().max(500),
  public_name: yup.string().required().max(50),
  installable: yup.boolean(),
  sold_by: yup.string(),
  notes: yup.string(),
  showcase_video: yup.object().required(),
  remove_media_ids: yup.array(yup.string()),
  beta: yup.boolean().nullable(),
  security_badge: yup.boolean().nullable(),
  security_questionnaire_document: yup.object().nullable(),
});

// region_or_country has to have at least one key present
const regionTabValidations = yup
  .object()
  .shape({
    countries: yup.object(),
    regions: yup.object(),
    pricing_description: yup.string().required(),
    pricing_url: yup.string().url(),
    has_trials: yup.boolean().required(),
    connector_required: yup.boolean().required(),
    subscription_required: yup.boolean().required(),
  })
  .test('region-or-country', 'regionOrCountry', (obj, { createError }) => {
    const region = Object.values(obj.regions).some((region) => region);
    const country = Object.values(obj.countries).some((country) => country);
    if (!country && !region) {
      return createError({
        path: `${MARKETPLACE_TABS.REGIONS}.region_or_country`,
        message: 'fieldRequired',
      });
    }
    return true;
  });

const supportTabValidations = yup.object().shape({
  support_email: yup.string().email().required(),
  website_link: yup.object().shape({
    url: yup.string().url().required(),
    label: yup.string().required(),
  }),
});

// return errors for each value the array is missing or equals '' and requires 3 items
const featuresTabValidations = yup.object().shape({
  feature_bullets: yup.array().of(yup.string().required()),
});

const mediaTabValidations = yup.object().shape({
  small_logo_url: yup.string().required(),
  small_logo: yup.mixed().required(),
  remove_small_logo: yup.boolean(),
  pictures: yup.array().required().min(3),
  remove_media_ids: yup.array(yup.string()),
  // when empty array, return fieldIsRequired
  videos: yup.array(),
});

const integrationValidations = yup.object().shape({
  requirements: yup.array().of(yup.string()),
});

const linkValidations = yup.object().shape({
  helpful_links: yup.array(
    yup.object().shape({
      label: yup.string(),
      url: yup.string().url(),
    })
  ),
});

const toolValidations = yup.object().shape({
  product_tools: yup.object(),
});

// if create_lead no validation
// otherwise one of either contact_us_email or learn_how_url is required
const contactValidations = yup
  .object()
  .shape({
    contact_us_email: yup.string().email().nullable(),
    learn_how_url: yup.string().url().nullable(),
    create_lead: yup.boolean(),
  })
  .test('contact-or-learn-how', 'fieldRequired', (val, { createError }) => {
    if(!val.contact_us_email && !val.learn_how_url && !val.create_lead) {
      return createError({
        path: `${MARKETPLACE_TABS.CONTACT}.contact_or_learn_how`,
        message: 'fieldRequired',
      });
    };
    return true;
  });

const getValidator = (tab) => {
  switch (tab) {
    case MARKETPLACE_TABS.APP:
      return appTabValidations;
    case MARKETPLACE_TABS.REGIONS:
      return regionTabValidations;
    case MARKETPLACE_TABS.SUPPORT:
      return supportTabValidations;
    case MARKETPLACE_TABS.FEATURES:
      return featuresTabValidations;
    case MARKETPLACE_TABS.LINKS:
      return linkValidations;
    case MARKETPLACE_TABS.MEDIA:
      return mediaTabValidations;
    case MARKETPLACE_TABS.REQUIREMENTS:
      return integrationValidations;
    case MARKETPLACE_TABS.CONTACT:
      return contactValidations;
    case MARKETPLACE_TABS.TOOLS:
    default:
      return null;
  }
};

// the field for these is country/region: { [id]: boolean } which yup doesn't handle well with
// the reach function so we short circuit validation for these fields
const skipValidation = (fieldPath) => {
  return fieldPath.some(
    (field) => field === 'countries' || field === 'regions'
  );
};

// There's probably a cleaner way to do this
// generates a string in shape of field[index].field2 ...
const generateFieldPathString = (fieldPath) => {
  return fieldPath
    .map((field) => {
      if (!isNaN(field)) {
        return `[${field}]`;
      }
      return `.${field}`;
    })
    .join('')
    .slice(1);
};

export const validateSingleField = ([tab, ...fieldPath], value) => {
  const path = generateFieldPathString(fieldPath);
  const schema = getValidator(tab);
  if (!schema || skipValidation(fieldPath)) {
    return;
  }
  const validator = yup.reach(schema, path);
  try {
    validator.validateSync(value, { stripUnknown: true });
  } catch (error) {
    return error;
  }
};

export const isTabValid = (tab, fields) => {
  const schema = getValidator(tab);
  if (!schema) {
    return true;
  }
  try {
    schema.validateSync(fields);
    return true;
  } catch (_) {
    return false;
  }
};

const globalValidator = yup.object().shape({
  [MARKETPLACE_TABS.APP]: appTabValidations,
  [MARKETPLACE_TABS.REGIONS]: regionTabValidations,
  [MARKETPLACE_TABS.SUPPORT]: supportTabValidations,
  [MARKETPLACE_TABS.FEATURES]: featuresTabValidations,
  [MARKETPLACE_TABS.MEDIA]: mediaTabValidations,
  [MARKETPLACE_TABS.REQUIREMENTS]: integrationValidations,
  [MARKETPLACE_TABS.LINKS]: linkValidations,
  [MARKETPLACE_TABS.TOOLS]: toolValidations,
  [MARKETPLACE_TABS.CONTACT]: contactValidations,
  [MARKETPLACE_TABS.PRICING]: yup.object().shape({
    pricing: yup.string(),
  }),
});

const emptyValidationMap = Object.keys(MARKETPLACE_TABS).reduce(
  (acc, tab) => ({
    ...acc,
    [tab]: null,
  }),
  {}
);

export const validateAllFields = (fields, I18n) => {
  try {
    globalValidator.validateSync(fields, {
      stripUnknown: false,
      abortEarly: false,
    });
    return emptyValidationMap;
  } catch (error) {
    return error.inner.reduce((errors, error) => {
      // convert strings of type tab.field[index].field2 to array of strings
      const path = error.path.match(/[^\]\[.]+/g);
      return assocPath(
        path,
        [I18n.t(`${I18n.locale}.errors.${error.message}`, error.params)],
        errors
      );
    }, {});
  }
};
