export const MARKETPLACE_TABS = {
  APP: 'app',
  REGIONS: 'pricingAvailability',
  SUPPORT: 'support',
  FEATURES: 'features',
  LINKS: 'links',
  TOOLS: 'tools',
  REQUIREMENTS: 'requirements',
  MEDIA: 'media',
  CONTACT: 'contact',
  SECURITY: 'security',
};

export const generateWistiaLink = (hashedVideoId) =>
  `https://fast.wistia.net/embed/iframe/${hashedVideoId}`;

export const REQUIRED_TABS = [
  MARKETPLACE_TABS.APP,
  MARKETPLACE_TABS.MEDIA,
  MARKETPLACE_TABS.REGIONS,
  MARKETPLACE_TABS.SUPPORT,
  MARKETPLACE_TABS.FEATURES,
  MARKETPLACE_TABS.CONTACT
];

