import styled from 'styled-components';
import { colors, Link } from '@procore/core-react';
import Form from '@/react/shared/form';

export const Divider = styled.div`
  height: 0;
  border-top: 1px solid ${colors.gray85};
`;

export const FormContentColumn = styled(Form.Content)`
  flex-direction: column;
`;

export const InlineLink = styled(Link)`
  padding-left: 4px;
`;