import React from 'react';
import { withStateHandlers } from 'recompose';
import { assocPath, omit, pathOr } from 'ramda';
import { errorsPresent } from '../../shared/validator';
import I18n from './i18n';
import { MARKETPLACE_TABS, REQUIRED_TABS } from './constants';
import {
  isTabValid,
  validateAllFields,
  validateSingleField,
} from './validations';

const mapFields = (unmappedFields) => {
  const fieldsClone = { ...unmappedFields };
  const nestedFields = [];

  // these functions delete fields from the clone
  // as they are plucked, so that the remaining
  // fields can be added on at the top level after
  // the nested ones are added to specific tabs
  const marketplaceFieldOr = (field, fallback) => {
    const value = fieldsClone[field];

    if (value) {
      nestedFields.push(field);
      return value;
    }
    return fallback;
  };

  const marketplaceFields = (...keys) =>
    keys.reduce((acc, key) => {
      if (key === 'small_logo_url') {
        acc.small_logo = {
          preview: fieldsClone.small_logo_url || '',
        };
      }
      acc[key] = fieldsClone[key];
      nestedFields.push(key);
      return acc;
    }, {});

  const regions = marketplaceFieldOr('regions', []).reduce((acc, product) => {
    acc[product.id] = true;
    return acc;
  }, {});

  const countries = marketplaceFieldOr('countries', []).reduce(
    (acc, product) => {
      acc[product.id] = true;
      return acc;
    },
    {}
  );

  const productTools = marketplaceFieldOr('product_tools', []).reduce(
    (acc, product) => {
      acc[product.id] = true;
      return acc;
    },
    {}
  );

  return {
    id: fieldsClone.id,
    [MARKETPLACE_TABS.APP]: {
      app_version_id: fieldsClone?.app_version?.id,
      ...marketplaceFields(
        'public_name',
        'description',
        'tagline',
        'category_ids',
        'built_by',
        'about',
        'how_it_works',
        'costs_money',
        'beta',
        'security_badge',
        'security_questionnaire_document',
        'sold_by',
        'installable',
        'app_version',
        'draft_developer_notes',
        'notes',
        'showcase_video'
      ),
    },
    [MARKETPLACE_TABS.FEATURES]: {
      feature_bullets: marketplaceFieldOr('feature_bullets', ['', '', '']),
    },
    [MARKETPLACE_TABS.LINKS]: {
      helpful_links: marketplaceFieldOr('helpful_links', [
        { label: '', url: '' },
        { label: '', url: '' },
        { label: '', url: '' },
      ]),
    },
    [MARKETPLACE_TABS.MEDIA]: marketplaceFields(
      'id',
      'small_logo',
      'small_logo_url',
      'pictures',
      'videos'
    ),
    [MARKETPLACE_TABS.CONTACT]: marketplaceFields(
      'learn_how_url',
      'contact_us_email',
      'create_lead'
    ),
    [MARKETPLACE_TABS.REGIONS]: {
      regions: regions,
      countries: countries,
      ...marketplaceFields(
        'subscription_required',
        'connector_required',
        'has_trials',
        'pricing_url',
        'pricing_description'
      ),
    },
    [MARKETPLACE_TABS.TOOLS]: { product_tools: productTools },
    [MARKETPLACE_TABS.REQUIREMENTS]: {
      requirements: marketplaceFieldOr('requirements', ['']),
    },
    [MARKETPLACE_TABS.SUPPORT]: {
      ...{ website_link: marketplaceFieldOr('website_link', {}) },
      ...marketplaceFields('support_email'),
    },
    [MARKETPLACE_TABS.SECURITY]: marketplaceFields(
      'security_questionnaire_visible'
    ),
    ...omit(nestedFields, fieldsClone), // Add any remaining keys at the top level
  };
};

export default (WrappedComponent) =>
  withStateHandlers(
    ({ activeTab }) => ({
      fields: {},
      publishedFields: {},
      tab: activeTab || MARKETPLACE_TABS.APP,
      loadingValuesMap: {
        global: false,
        video: false,
      },
      validations: {},
      valid: true,
      errors: [],
      errorsPresent,
    }),
    {
      switchTab:
        (_, { activeTab, setTab }) =>
        (tab) => {
          // If no active tab is passed in, just set the tab locally
          // Otherwise, set the tab in the parent component
          // to be used in a future stage of the self service work
          if (!activeTab) {
            return { tab };
          }
          setTab(tab);
        },
      setValid: () => (valid) => ({ valid }),
      setError: () => (error) => {
        if (!error) {
          return { errors: [] };
        }

        if (!error.request) {
          return { errors: [error.message] };
        }

        try {
          const resp = JSON.parse(error.request.response);
          if (resp.errors) {
            return { errors: resp.errors };
          }
        } catch (err) {
          return { errors: [err.message] };
        }

        return { errors: [] };
      },
      mapMarketplaceAppFields:
        (_, { setTotalProgress }) =>
        (unmappedFields, unmappedPublishedFields) => {
          const fields = mapFields(unmappedFields);
          const publishedFields = mapFields(unmappedPublishedFields);
          if (setTotalProgress) {
            setTotalProgress(
              REQUIRED_TABS.map((tab) => ({
                tab,
                valid: isTabValid(tab, fields[tab]),
              }))
            );
          }
          return {
            fields,
            publishedFields,
          };
        },
      setVideos:
        ({ fields }) =>
        (videos) => {
          return {
            fields: assocPath(
              [MARKETPLACE_TABS.MEDIA, 'videos'],
              videos,
              fields
            ),
          };
        },
      setImages:
        ({ fields }) =>
        (images) => {
          return {
            fields: assocPath(
              [MARKETPLACE_TABS.MEDIA, 'pictures'],
              images,
              fields
            ),
          };
        },
      setLoadingValue:
        ({ loadingValuesMap }) =>
        (key, value) => ({
          loadingValuesMap: {
            ...loadingValuesMap,
            [key]: !!value,
          },
        }),
      validateFields:
        ({ fields }, { setTotalProgress }) =>
        (success, error) => {
          const fieldsClone = { ...fields };
          const yupValidations = validateAllFields(fieldsClone, I18n);

          const valid = !errorsPresent(yupValidations);

          if (valid) {
            success();
          } else {
            error();
          }

          if (setTotalProgress) {
            setTotalProgress(
              REQUIRED_TABS.map((tab) => ({
                tab,
                valid: isTabValid(tab, fields[tab]),
              }))
            );
          }

          return {
            validations: yupValidations,
            valid,
          };
        },
      // eslint-disable-next-line no-shadow
      onChange:
        ({ fields, validations }, { onChange, setProgress }) =>
        (fieldPath, value) => {
          const fieldsClone = { ...fields };
          let validationsClone = { ...validations };

          // fieldPath here is either a string key
          // for a field, or an array that gives a
          // deep path to the field in the state
          const fullPath =
            typeof fieldPath === 'string' ? [fieldPath] : fieldPath;
          const updatedFields = assocPath(fullPath, value, fieldsClone);
          const validationResult = validateSingleField(fullPath, value);
          // Get the root path (e.g., feature_bullets, requirements, helpful_links)
          const rootFieldPath = fullPath.slice(0, -1);
          const fieldIndex = fullPath[fullPath.length - 1]; // index of the field in the array

          if (validationResult) {
            // Add the error for the specific field
            validationsClone = assocPath(
              fullPath,
              [
                I18n.t(
                  `${I18n.locale}.errors.${validationResult.message}`,
                  validationResult?.params
                ),
              ],
              validationsClone
            );
          } else {
            // Remove the error for the specific field, but ensure others are preserved
            const currentErrors = pathOr([], rootFieldPath, validationsClone);

            // Ensure we don't completely remove the errors for other fields
            currentErrors[fieldIndex] = null; // Set only the current index error to null
            validationsClone = assocPath(
              rootFieldPath,
              currentErrors,
              validationsClone
            );
          }

          // If the field is a region or country, remove the region_or_country error
          // TODO: clean the below up, it's a hack solution
          if (
            fullPath.some(
              (field) => field === 'countries' || field === 'regions'
            )
          ) {
            validationsClone[fullPath[0]]['region_or_country'] = null;
          }

          // Set the section modified flag to true
          updatedFields.__modified__ = true;

          // If an onChange handler is passed in, call it as well.
          onChange && onChange(fields, updatedFields);

          return {
            fields: updatedFields,
            validations: validationsClone,
          };
        },
    }
  )(({ ...props }) => <WrappedComponent {...props} />);
