import React, { useState } from 'react';
import PT from 'prop-types';
import { Flex, Switch, Typography } from '@procore/core-react';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../constants';
import { Divider, FormContentColumn } from '../utils';

const TabSecurity = ({ I18n, fields, onFieldChange }) => {
  const [questionnaireEnabled, setQuestionnaireEnabled] = useState(
    !!fields?.security_questionnaire_visible
  );

  const onChange = (fieldPath, value) =>
    onFieldChange([MARKETPLACE_TABS.SECURITY, fieldPath], value);

  return (
    <FormContentColumn>
      <Flex>
        <Form.Column>
          <Flex
            data-testid="marketplace-security-questionnaire-option"
            alignContent="flex-start"
            alignItems="center"
          >
            <Typography data-testid="marketplace-security-questionnaire-option-title">
              <Typography weight="semibold">
                {I18n.t('labels.marketplaceApp.securityQuestionnaire.title')}
              </Typography>
              <Flex marginTop="xs">
                {I18n.t(
                  'labels.marketplaceApp.securityQuestionnaire.description'
                )}
              </Flex>
            </Typography>
          </Flex>
          <Flex
            alignContent="flex-start"
            alignItems="center"
            paddingTop="md"
            paddingBottom={questionnaireEnabled && 'lg'}
          >
            <Switch
              data-testid="marketplace-security-questionnaire-option-switch"
              checked={questionnaireEnabled}
              onChange={() => {
                setQuestionnaireEnabled(!questionnaireEnabled);
                onChange(
                  'security_questionnaire_visible',
                  !questionnaireEnabled
                );
              }}
            >
              <Typography>
                {I18n.t(
                  `labels.marketplaceApp.securityQuestionnaire.${
                    questionnaireEnabled ? 'labelDisabled' : 'labelEnabled'
                  }`
                )}
              </Typography>
            </Switch>
          </Flex>
          {questionnaireEnabled ? (
            <Flex data-testid="marketplace-security-questionnaire">
              <Form.Column>
                <Divider />
                <Typography intent="h2" style={{ marginTop: '16px' }}>
                  Questionnaire Goes Here
                </Typography>
              </Form.Column>
            </Flex>
          ) : null}
        </Form.Column>
      </Flex>
    </FormContentColumn>
  );
};

TabSecurity.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  fields: PT.shape({
    security_questionnaire_visible: PT.bool,
  }).isRequired,
  onFieldChange: PT.func.isRequired,
};

export default TabSecurity;
