import React, { createContext, useContext, useState } from 'react';
import PT from 'prop-types';
import {
  METRICS_DATA_PT,
} from '@/react/shared/developerAppShowPropTypes';
import { ALL_TABS } from './SideNav/SideNav.constants';
import api from './api';
import { trackEvent } from '@/react/shared/analytics';

export const ShowContext = createContext(null);

export const UseShowContext = () => {
  return useContext(ShowContext);
};

export const ShowContextProvider = ({
  children,
  wistiaApiKey,
  wistiaMarketplaceProjectId,
  ...props
}) => {
  const [devApp, setDevApp] = useState({});
  const [appVersion, setAppVersion] = useState({});
  const [permissions, setPermissions] = useState({});
  const [editingPermissions, setEditingPermissions] = useState({});
  const [currentManifest, setCurrentManifest] = useState({});
  const [productionManifests, setProductionManifests] = useState([]);

  const trackAnalyticsEvent = (key, params) => {
    trackEvent(key, {
      developer_app_id: devApp?.id,
      app_name: devApp?.internal_name,
      ...params,
    });
  };

  const ContextObject = {
    ...props,
    api: api(wistiaApiKey, wistiaMarketplaceProjectId),
    state: {
      devApp,
      setDevApp,
      appVersion,
      setAppVersion,
      permissions,
      setPermissions,
      currentManifest,
      setCurrentManifest,
      editingPermissions,
      setEditingPermissions,
      productionManifests,
      setProductionManifests,
      trackEvent: trackAnalyticsEvent,
    },
  };

  return (
    <ShowContext.Provider value={ContextObject}>
      {children}
    </ShowContext.Provider>
  );
};

ShowContextProvider.propTypes = {
  canCreateSandboxManifests: PT.bool,
  canModifyManifests: PT.bool,
  canSetSemanticVersions: PT.bool,
  manifestSupportDocPath: PT.string,
  developerEmail: PT.string.isRequired,
  developerAppId: PT.string.isRequired,
  canEdit: PT.bool.isRequired,
  canDestroy: PT.bool.isRequired,
  regions: PT.arrayOf(PT.shape({})),
  wistiaApiKey: PT.string.isRequired,
  wistiaMarketplaceProjectId: PT.string.isRequired,
  productTools: PT.arrayOf(PT.shape({})).isRequired,
  marketplaceReviewerEmail: PT.string.isRequired,
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
  metricsData: PT.shape(METRICS_DATA_PT.TYPES),
  developerAppMetricsPath: PT.string,
  appCredentialsDocPath: PT.string.isRequired,
  redirectUriDocPath: PT.string.isRequired,
  isProcoreEmployee: PT.bool,
  dmsaPermissions: PT.object.isRequired,
  secretResetCount: PT.number.isRequired,
  children: PT.shape({}),
};
